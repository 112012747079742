import React, { useEffect, useState } from 'react';
import './header.css';
import LogoutButton from '../../login/logout';
import logo from '../../images/logoLightnoDigilab.png';
// import logo from '../../images/logoLight.png';

const Header = ({ onLinkClick, tier, dataID }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State to handle mobile menu open/close
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for MyPractice dropdown
  const [colorMain, setColorMain] = useState("#007bff");
  const [colorSecond, setColorSecond] = useState("#fff");

  useEffect(() => {
    if (tier === "2") {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/branding?dataID=${dataID.dataID}`);
          const data = await response.json();
          const logoURL = data.logoURL?.S || '';
          const colourMain = data.colourMain?.S || '';
          const colourSecond = data.colourSecond?.S || '';
          setColorMain(colourMain);
          setColorSecond(colourSecond);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [tier, dataID]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggles the state
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false); // Close the menu when a link is clicked
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown state
  };

  const tierLabel = tier === "1" ? "Physio" : tier === "2" ? "Physio Plus" : "Physio";

  return (
    <header className='headerPhysio' style={{ backgroundColor: colorSecond }}>
      <div className="logo-placeholderNone">
        <img src={logo} alt='AthlioConnect Logo' className='logo' />
      </div>
      <h1 className="header-title" style={{ color: colorMain }}>{tierLabel}</h1>

      {/* Hamburger Icon for mobile view */}
      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Standard Nav for larger screens */}
      <nav className="desktop-nav">
        <ul>
          <li><a onClick={() => onLinkClick('home')} style={{ color: colorMain }}>Home</a></li>
          <li><a onClick={() => onLinkClick('training')} style={{ color: colorMain }}>Training</a></li>
          {tier !== "2" && (
            <li><a onClick={() => onLinkClick('about')} style={{ color: colorMain }}>About</a></li>
          )}
          <li><a onClick={() => onLinkClick('profile')} style={{ color: colorMain }}>Profile</a></li>
          {tier === "2" && (
            <li className="dropdown">
              <a onClick={toggleDropdown} style={{ color: colorMain }}>MyPractice</a>
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li><a onClick={() => onLinkClick('newsletter')} style={{ color: colorMain }}>Newsletter</a></li>
                  <li><a onClick={() => onLinkClick('alerts')} style={{ color: colorMain }}>Alerts</a></li>
                </ul>
              )}
            </li>
          )}
          {tier !== "2" && (
            <li><a href="https://yjabkt-xa.myshopify.com/" target="_blank" rel="noopener noreferrer" style={{ color: colorMain }}>Merch</a></li>
          )}
          <li><LogoutButton /></li>
        </ul>
      </nav>

      {/* Mobile flyout menu - toggle with state */}
      <div className={isMobileMenuOpen ? 'mobile-menu mobile-menu-open' : 'mobile-menu'}>
        <ul>
          <li><a onClick={() => { onLinkClick('home'); closeMobileMenu(); }} style={{ color: colorMain }}>Home</a></li>
          <li><a onClick={() => { onLinkClick('training'); closeMobileMenu(); }} style={{ color: colorMain }}>Training</a></li>
          {tier !== "2" && (
            <li><a onClick={() => { onLinkClick('about'); closeMobileMenu(); }} style={{ color: colorMain }}>About</a></li>
          )}
          <li><a onClick={() => { onLinkClick('profile'); closeMobileMenu(); }} style={{ color: colorMain }}>Profile</a></li>
          {tier === "2" && (
            <li className="dropdown">
              <a onClick={toggleDropdown} style={{ color: colorMain }}>MyPractice</a>
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li><a onClick={() => { onLinkClick('newsletter'); closeMobileMenu(); }} style={{ color: colorMain }}>Newsletter</a></li>
                  <li><a onClick={() => { onLinkClick('alerts'); closeMobileMenu(); }} style={{ color: colorMain }}>Alerts</a></li>
                </ul>
              )}
            </li>
          )}
          {tier !== "2" && (
            <li><a href="https://yjabkt-xa.myshopify.com/" target="_blank" rel="noopener noreferrer" style={{ color: colorMain }}>Merch</a></li>
          )}
          <li><LogoutButton /></li>
        </ul>
      </div>
    </header>
  );
};

export default Header;

import React, { useState } from "react";
import '../practice/practice.css';

const PracticeLicences = () => {
    // Dummy data for licenses (this would come from a database or API in a real app)
    const [licences, setLicences] = useState([
        { id: 1, name: "General Practice Licence", practitioners: 5, expiryDate: "2024-10-01", tier: 1 },
        // Removed the Specialist Practice Licence from the list
    ]);

    // Function to handle licence renewal (placeholder)
    const handleRenew = (id) => {
        alert("Please contact AthlioConnect for licencing")
        // You can add logic to update the expiry date, make API call, etc.
    };

    return (
        <div className="containerr">
            <h2>Practice Licences</h2>
            <div className="licences">
                {licences.map(licence => (
                    <div key={licence.id} className="licence-card">
                        <h3>{licence.name}</h3>
                        <p>Number of Practitioners: {licence.practitioners}</p>
                        <p>Renewal Date: {new Date(licence.expiryDate).toLocaleDateString()}</p>
                        <p>Subscription Tier: Tier {licence.tier}</p>
                        <button onClick={() => handleRenew(licence.id)} className="renew-button">Renew Licence</button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PracticeLicences;

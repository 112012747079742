import React, { useEffect, useState } from 'react';
import './practice.css'

const CaseManage = ({clKey}) => {
  const [activeCases, setActiveCases] = useState(0);
  const [totalCases, setTotalCases] = useState(0);

  // const getCases = async () => {
  //   try {
  //     const resp = await fetch(`http://localhost:5000/api/activeCases`, {
  //       method: 'GET',
  //       headers: {
  //         'x-api-key': clKey, 
  //       },
  //     });
  //     const data = await resp.json();
  //     setActiveCases(data.patient_cases.length); // Set active cases count
  //   } catch (error) {
  //     console.error('Error fetching active cases:', error);
  //   }
  // };
  const getCases = async () => {
    try {
      const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/activeCases`, {
        method: 'GET',
        headers: {
          'x-api-key': clKey, 
        },
      });
      const data = await resp.json();
      setActiveCases(data.patient_cases.length); // Set active cases count
    } catch (error) {
      console.error('Error fetching active cases:', error);
    }
  };

  // const getAllCases = async () => {
  //   try {
  //     const resp = await fetch(`http://localhost:5000/api/allCases`, {
  //       method: 'GET',
  //       headers: {
  //         'x-api-key': clKey, 
  //       },
  //     });
  //     const data = await resp.json();
  //     setTotalCases(data.patient_cases.length); // Set total cases count
  //   } catch (error) {
  //     console.error('Error fetching all cases:', error);
  //   }
  // };
  const getAllCases = async () => {
    try {
      const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/allCases`, {
        method: 'GET',
        headers: {
          'x-api-key': clKey, 
        },
      });
      const data = await resp.json();
      setTotalCases(data.patient_cases.length); // Set total cases count
    } catch (error) {
      console.error('Error fetching all cases:', error);
    }
  };

  useEffect(() => {
    getCases();
    getAllCases();
  }, []);

  return (
    <div className='container'>
      <h2>Case Manager</h2>
      <p>Active Cases: {activeCases}</p>
      <p>Total Cases: {totalCases}</p>
    </div>
  );
};

export default CaseManage;

import React, { useEffect } from "react";
import "./physioContent.css";
import PatientDropdown from "./PatientDropdown";
import NewAlerts from "./NewAlerts";
import PhysioProgramming from "./PhysioProgramming";
import PhysioVideos from "./PhysioVideos";

const PhysioMainContent = ({ handlePatientSelect, selectedPatient, patientData, refreshPTData, dataID }) => {

  return (
    <div className="physioMainContent" style={{width: "100%"}}>
      {/* <button onClick={refreshPTData}>Refresh Data</button> */}
      <PatientDropdown onSelect={handlePatientSelect} dataID={dataID}/>
      {patientData && (
        <>
          <h2 className="patientName">{selectedPatient.ptName}</h2>
          <NewAlerts patient={patientData} />
          <PhysioProgramming patient={patientData} />
          <PhysioVideos patient={patientData} />
        </>
      )}
    </div>
  );
};

export default PhysioMainContent;

import React, { useState } from 'react';
import './header.css';
import LogoutButton from '../../login/logout';
import logo from '../../images/logoLightnoDigilab.png';
// import logo from '../../images/logoLight.png';

const Header = ({ onLinkClick, tier }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State to handle mobile menu open/close

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggles the state
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false); // Close the menu when a link is clicked
  };

  const tierLabel = tier === "1" ? "Coach" : tier === "2" ? "Coach Plus" : "Coach Base";

  return (
    <header className='headerPhysio'>
      <div className="logo-placeholder">
        <img src={logo} alt='AthlioConnect Logo' className='logo' />
      </div>
      <h1 className="header-title">{tierLabel}</h1>
      
      {/* Hamburger Icon for mobile view */}
      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Standard Nav for larger screens */}
      <nav className="desktop-nav">
        <ul>
          <li><a onClick={() => onLinkClick('home')}>Home</a></li>
          <li><a onClick={() => onLinkClick('training')}>Training</a></li>
          <li><a onClick={() => onLinkClick('about')}>About</a></li>
          <li><a onClick={() => onLinkClick('profile')}>Profile</a></li>
          <li><a href="https://yjabkt-xa.myshopify.com/" target="_blank" rel="noopener noreferrer">Merch</a></li>
          <li><LogoutButton /></li>
        </ul>
      </nav>

      {/* Mobile flyout menu - toggle with state */}
      <div className={isMobileMenuOpen ? 'mobile-menu mobile-menu-open' : 'mobile-menu'}>
        <ul>
          <li><a onClick={() => { onLinkClick('home'); closeMobileMenu(); }}>Home</a></li>
          <li><a onClick={() => { onLinkClick('training'); closeMobileMenu(); }}>Training</a></li>
          <li><a onClick={() => { onLinkClick('about'); closeMobileMenu(); }}>About</a></li>
          <li><a onClick={() => { onLinkClick('profile'); closeMobileMenu(); }}>Profile</a></li>
          <li><a href="https://yjabkt-xa.myshopify.com/" target="_blank" rel="noopener noreferrer">Merch</a></li>
          <li><LogoutButton /></li>
        </ul>
      </div>
    </header>
  );
};

export default Header;

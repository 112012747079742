import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51Psq2PRuxSNwOr4wqymLyf8OJkc5T8rLjnVJ9qHxaszpqUUl1LZGBiAS8dcWlmQW6E7hRPUYIhgmOU5ECadnNCvE00zWwy24nB"
);

function Payment() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const appType = searchParams.get("appType");
  const appTime = searchParams.get("appTime");
  const startDate = searchParams.get("startDate");
  const [physioType, setPhysioType] = useState("senior");
  const [clientSecret, setClientSecret] = useState("");
  const [isPaymentIntentCreated, setIsPaymentIntentCreated] = useState(false);

  // Handle Buy Now button click
  const handleBuyNow = async () => {
    if (!physioType) {
      alert("Please select a physio type");
      return;
    }

    try {
      // Send request to your backend to create a PaymentIntent
      const response = await fetch(
        "http://localhost:5000/create-payment-intent",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            physioType, // send which physio type was selected to the server
          }),
        }
      );

      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
      setIsPaymentIntentCreated(true);
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };

  // Options to pass to the Elements component
  const options = {
    clientSecret,
  };

  return (
    <div>
      <h2>Pay for your appointment in person - online payment coming soon</h2>

      <p>
        {appType} at {new Date(startDate).toLocaleString()} for {appTime}{" "}
        minutes
      </p>

      <button >Buy Now (coming soon)</button>

      <button>Cancel (coming soon)</button>

      {/* Show payment form only when payment intent is created */}
      {isPaymentIntentCreated && clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      ) : (
        <p>Loading payment form...</p>
      )}
    </div>
  );
}

export default Payment;

import React, { useState, useEffect } from "react";
import "./physioContent.css";

const PhysioProgramming = ({ patient }) => {
  const [programming, setProgramming] = useState([]);

  useEffect(() => {
    if (patient && patient.length > 0) {
      const currentPatient = patient[0];
      setProgramming(currentPatient.Programming || []);
    }
  }, [patient]); // Re-run this effect whenever `patient` changes

  const [newExercise, setNewExercise] = useState({
    Exercise: "",
    Sets: "",
    Reps: "",
    Notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExercise({ ...newExercise, [name]: value });
  };

  const handleProgrammingChange = (index, name, value) => {
    const updatedProgramming = programming.map((program, i) =>
      i === index ? { ...program, [name]: value } : program
    );
    setProgramming(updatedProgramming);
  };

  const addExercise = () => {
    const updatedProgramming = [...programming, newExercise];
    setProgramming(updatedProgramming);
    updateProgramming(updatedProgramming);
    setNewExercise({
      Exercise: "",
      Sets: "",
      Reps: "",
      Notes: "",
    });
  };

  const removeExercise = (index) => {
    const updatedProgramming = programming.filter((_, i) => i !== index);
    setProgramming(updatedProgramming);
    updateProgramming(updatedProgramming);
  };

  const updateProgramming = async (updatedProgramming) => {
    if (!patient || patient.length === 0) return;
    const currentPatient = patient[0];
    
    const response = await fetch(
      `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/programming/updatePTProgramming?userId=${currentPatient.UserID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Programming: updatedProgramming }),
      }
    );
    const data = await response.json();
    console.log(data);
  };

  return (
    <div className="container">
      <h2>Physio Programming</h2>
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              <th>Exercise</th>
              <th>Sets</th>
              <th>Reps</th>
              <th>Notes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {programming.map((program, index) => (
              <tr key={index}>
                <td className="prgEx">{program.Exercise}</td>
                <td>
                  <input
                    type="text"
                    value={program.Sets}
                    onChange={(e) =>
                      handleProgrammingChange(index, "Sets", e.target.value)
                    }
                    className="inputPrg"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={program.Reps}
                    onChange={(e) =>
                      handleProgrammingChange(index, "Reps", e.target.value)
                    }
                    className="inputPrg"
                  />
                </td>
                <td>{program.Notes}</td>
                <td>
                  <button onClick={() => removeExercise(index)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="form-group">
        <h3>Add New Exercise</h3>
        <input
          type="text"
          name="Exercise"
          placeholder="Exercise"
          value={newExercise.Exercise}
          onChange={handleInputChange}
          className="newExInput"
        />
        <input
          type="text"
          name="Sets"
          placeholder="Sets"
          value={newExercise.Sets}
          onChange={handleInputChange}
          className="newExInput"
        />
        <input
          type="text"
          name="Reps"
          placeholder="Reps"
          value={newExercise.Reps}
          onChange={handleInputChange}
          className="newExInput"
        />
        <input
          type="text"
          name="Notes"
          placeholder="Notes"
          value={newExercise.Notes}
          onChange={handleInputChange}
          className="newExInput"
        />
        <button onClick={addExercise} className="prgButton">Add Exercise</button>
      </div>
      <button onClick={() => updateProgramming(programming)} className="prgButton">
        Update Programming
      </button>
    </div>
  );
};

export default PhysioProgramming;

import React from 'react';
import './coffee.css'; // Update path if necessary

const CoffeeAnimation = () => {
  return (
    <div className="coffee">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default CoffeeAnimation;

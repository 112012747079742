import React, { useState } from 'react';
import './advice.css';

const GoalSettingContent = () => {
  return (
    <div>
      <p>Setting clear and achievable goals is crucial for success in any fitness journey. Here are some tips to help you set effective goals:</p>
      <ul>
        <li>Be specific: Define exactly what you want to achieve. For example, instead of saying "I want to get fit," specify "I want to run a 5k in under 30 minutes."</li>
        <li>Make them measurable: Set goals that you can track and measure your progress against. This could be a certain weight, number of reps, or distance.</li>
        <li>Set realistic targets: While it's good to challenge yourself, make sure your goals are attainable within a reasonable timeframe.</li>
        <li>Set short-term and long-term goals: Break down your larger fitness goals into smaller, more manageable milestones.</li>
        <li>Write them down: Putting your goals in writing can make them feel more tangible and increase your commitment to achieving them.</li>
        <li>Review and adjust: Regularly review your goals and adjust them as needed based on your progress and changing circumstances.</li>
      </ul>
    </div>
  );
};

const ExerciseTipsContent = () => {
  return (
    <div>
      <p>Exercise tips can help you maximize the effectiveness of your workouts and reduce the risk of injury. Here are some key tips to keep in mind:</p>
      <ul>
        <li>Warm up before training - this is a given!</li>
        <li>Focus on proper form and technique - this will increase the mind muscle connection and reduce risk of injury.</li>
        <li>Vary your workouts to prevent boredom but be consistent.</li>
        <li>Listen to your body and rest when needed.</li>
        <li>Stay hydrated and fuel your body properly, try to get more than 1.6g of protein per kg of body weight</li>
      </ul>
    </div>
  );
};

const TrainingAdaptationsContent = () => {
  return (
    <div>
      <p>Adapting your training program based on your progress and changing needs is essential for long-term success. Here are some strategies for effective training adaptations:</p>
      <ul>
        <li>Progressive overload: Gradually increase the intensity, duration, or frequency of your workouts to continue challenging your body and making progress. E.g. Increase by a rep each week then increase the weight.</li>
        <li>Periodisation: Divide your training program into distinct phases, such as building strength, endurance, or power, to optimize performance and prevent overtraining.</li>
        <li>Rest and Recovery: You aren't resting enough - rest harder! If you're actually having a rest day - rest! Feet up, foam roll and napping works well for me!</li>
        <li>Listen to feedback: Pay attention to how your body responds to training and adjust your program accordingly. If something isn't working, don't be afraid to make changes.</li>
      </ul>
    </div>
  );
};

const AdviceContent = ({ userRole }) => {
  const [isGoalSettingOpen, setIsGoalSettingOpen] = useState(true);
  const [isExerciseTipsOpen, setIsExerciseTipsOpen] = useState(true);
  const [isTrainingAdaptationsOpen, setIsTrainingAdaptationsOpen] = useState(true);

  const toggleGoalSetting = () => setIsGoalSettingOpen(!isGoalSettingOpen);
  const toggleExerciseTips = () => setIsExerciseTipsOpen(!isExerciseTipsOpen);
  const toggleTrainingAdaptations = () => setIsTrainingAdaptationsOpen(!isTrainingAdaptationsOpen);

  const canEdit = userRole === 'physio' || userRole === 'god';

  return (
    <div className="advice-content">
      {canEdit && <button>Edit Content</button>}

      <div className="section-header" onClick={toggleGoalSetting}>
        <h2>Goal Setting</h2>
        <span>{isGoalSettingOpen ? '-' : '+'}</span>
      </div>
      {isGoalSettingOpen && (
        <div className="section-content">
          <GoalSettingContent />
        </div>
      )}

      <div className="section-header" onClick={toggleExerciseTips}>
        <h2>Exercise Tips</h2>
        <span>{isExerciseTipsOpen ? '-' : '+'}</span>
      </div>
      {isExerciseTipsOpen && (
        <div className="section-content">
          <ExerciseTipsContent />
        </div>
      )}

      <div className="section-header" onClick={toggleTrainingAdaptations}>
        <h2>Training Adaptations</h2>
        <span>{isTrainingAdaptationsOpen ? '-' : '+'}</span>
      </div>
      {isTrainingAdaptationsOpen && (
        <div className="section-content">
          <TrainingAdaptationsContent />
        </div>
      )}
    </div>
  );
};

export default AdviceContent;

import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import "./createProfile.css";

const CreateAthleteProfile = ({ onProfileCreated, dataID }) => {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [primarySport, setPrimarySport] = useState("");
  const [goal, setGoal] = useState("");

  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    // Array of image paths
    const images = [
      require("../../images/background1.jpg"),
      require("../../images/background2.jpg"),
      require("../../images/background3.jpg"),
      require("../../images/background4.jpg"),
      require("../../images/background5.jpg"),
    ];

    // Select a random image
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []);

  const createAthleteProfile = async (profileData) => {
    const response = await fetch(
      "https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athleteProfile/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profileData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create athlete profile");
    }

    return response;
  };

  const createNameRelation = async (nameRelationData) => {
    const response = await fetch(
      "https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/name-Relation/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nameRelationData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create name relation");
    }

    return response;
  };

  const handleSubmit = async (e) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    e.preventDefault();

    const profileData = {
      dataID: {
        S: dataID,
      },
      Height: {
        S: CryptoJS.AES.encrypt(height.toString(), encryptionKey).toString(),
      },
      "Primary Sport": {
        S: CryptoJS.AES.encrypt(primarySport, encryptionKey).toString(),
      },
      TestResults: {
        M: {
          LactateThreshold: {
            S: CryptoJS.AES.encrypt("0", encryptionKey).toString(),
          },
          Vo2Max: {
            S: CryptoJS.AES.encrypt("0", encryptionKey).toString(),
          },
        },
      },
      Weight: {
        S: CryptoJS.AES.encrypt(weight.toString(), encryptionKey).toString(),
      },
    };

    const nameRelationData = {
      dataID: dataID,
      name: CryptoJS.AES.encrypt(name, encryptionKey).toString(),
    };

    try {
      // Call the function to create athlete profile
      const CAPResponse = await createAthleteProfile(profileData);
      console.log("Athlete profile:", CAPResponse);

      // Call the function to create name relation
      const CNRResponse = await createNameRelation(nameRelationData);
      console.log("Name relation: ", CNRResponse);

      // Assuming profile creation is successful, you call onProfileCreated
      if (onProfileCreated) {
        onProfileCreated();
      }
    } catch (error) {
      console.error("Error creating profile:", error);
    }
  };

  const handleTest = async (e) => {
    const nameRelationData = {
      dataID: "123698",
      name: "TEst Test",
    };
    const CNRResponse = await createNameRelation(nameRelationData);
    console.log("Name relation: ", CNRResponse);
  };

  return (
    <div
      className="mainContainer"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className=" mainContent">
        <h2>Create Profile</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Age:
              <input
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Height (cm):
              <input
                type="number"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Weight (kg):
              <input
                type="number"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Primary Sport:
              <input
                type="text"
                value={primarySport}
                onChange={(e) => setPrimarySport(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Goal:
              <input
                type="text"
                value={goal}
                onChange={(e) => setGoal(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit">Create Profile</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAthleteProfile;

import React from 'react';

const PhysioNewsletter = () => {
  return (
    <div style={styles.newsletterContainer}>
      <header style={styles.header}>
        <h1>Physio Practice Monthly Newsletter</h1>
        <p>Stay updated with the latest news, health tips, and events!</p>
      </header>
      <section style={styles.content}>
        <h2>Featured Health Tip of the Month</h2>
        <p>
          Regular stretching and mobility exercises can help improve flexibility and reduce muscle tightness. Start with simple routines that target major muscle groups.
        </p>
        <h2>Upcoming Events</h2>
        <ul>
          <li>🗓 Workshop on Injury Prevention - Nov 15th, 2024</li>
          <li>🗓 Free Physio Consultation Day - Dec 1st, 2024</li>
        </ul>
        <h2>Special Promotions</h2>
        <p>
          This month, we're offering 20% off on our advanced physio sessions for all new clients. Book your appointment now!
        </p>
      </section>
      <footer style={styles.footer}>
        <p>Follow us on social media for more updates!</p>
        <p>📧 Contact us at: newsletter@physiopractice.com</p>
      </footer>
    </div>
  );
};

const styles = {
  newsletterContainer: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  },
  header: {
    borderBottom: '2px solid #0d6efd',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  content: {
    marginBottom: '20px',
  },
  footer: {
    borderTop: '2px solid #0d6efd',
    paddingTop: '10px',
    fontSize: '0.9em',
    color: '#555',
  },
};

export default PhysioNewsletter;

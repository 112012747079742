import React, { useEffect, useState } from 'react';
import './nextAv.css';

const NextAvApps = ({ types, times, onFindNextAppointment, onFindWeekAppointments, appointmentData, appointmentDataList, weeklyData, onBookAppointment, onFindAppointments }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [appStartIso, setAppStartIso] = useState("");
  const [nextAppFormatted, setNextAppFormatted] = useState("");
  const [weeklyAppointments, setWeeklyAppointments] = useState([]);
  const [weekStart, setWeekStart] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState(null); // New state for selected appointment

  const handleTypeChange = (e) => {
    const selectedIndex = e.target.value;
    if (selectedIndex === '') {
      setSelectedType(null);
      setSelectedTime(null);
    } else {
      setSelectedType(types[selectedIndex]);
      setSelectedTime(times[selectedIndex]);
    }
  };

  const handleWeekStartChange = (e) => {
    const selectedDate = new Date(e.target.value);
    if (selectedDate.getDay() === 1) { // Ensure it's Monday
      const formattedDate = selectedDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      setWeekStart(formattedDate); // Set the ISO-formatted date
    } else {
      setWeekStart("");
    }
  };  

  const findNextAppointment = () => {
    if (onFindNextAppointment && selectedType && selectedTime) {
      onFindNextAppointment(selectedType, selectedTime);
    }
  };

  const findWeekAppointments = () => {
    if (onFindAppointments && selectedType && selectedTime && weekStart) {
      onFindAppointments(selectedType, weekStart); // Pass weekStart here
    } else if (!weekStart) {
      alert("Please select a Monday as the start of the week.");
    }
  };  

  const bookAppointment = () => {
    onBookAppointment(selectedType, selectedTime, selectedAppointment);
  };

  useEffect(() => {
    if (appointmentData) {
      const appointmentStart = appointmentData.appointment_start;
      if (appointmentStart) {
        setAppStartIso(appointmentStart);
        const localDate = new Date(appointmentStart);
        const formattedDate = localDate.toLocaleString('en-GB', { 
          timeZone: 'Europe/London',
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        setNextAppFormatted(formattedDate);
      } else {
        setNextAppFormatted("");
      }
    } else {
      setNextAppFormatted("");
    }
  }, [appointmentData]);

  useEffect(() => {
    if (appointmentDataList && appointmentDataList.available_times) {
      const formattedWeeklyList = appointmentDataList.available_times.map(appointment => {
        const date = new Date(appointment.appointment_start);
        return {
          iso: appointment.appointment_start, // ISO format for booking
          display: date.toLocaleString('en-GB', { 
            timeZone: 'Europe/London',
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
        };
      });
      setWeeklyAppointments(formattedWeeklyList);
    } else {
      setWeeklyAppointments([]);
    }
  }, [appointmentDataList]);

  return (
    <div>
      <div>
        <label>Select Appointment Type and Duration:</label>
        <select
          value={selectedType === null ? '' : types.indexOf(selectedType)}
          onChange={handleTypeChange}
          className='slt_NextAv'
        >
          <option value=''>Select an appointment type</option>
          {types.map((type, index) => (
            <option key={type} value={index}>
              {type} ({times[index]} minutes)
            </option>
          ))}
        </select>
      </div>

      <div>
        <h2>Next Available Appointments</h2>
        <button onClick={findNextAppointment} disabled={!selectedType || !selectedTime} className='btn_NextAv'>
          Find Next Appointment
        </button>
      </div>
      {nextAppFormatted ? (
        <div>
          <h3>Next Available Appointment:</h3>
          <p>{nextAppFormatted}</p>
          <button onClick={bookAppointment} className='btn_NextAv'>Book Now</button>
        </div>
      ) : (
        <p>No available times.</p>
      )}

      <div>
        <h2>Find Appointments for a Week</h2>
        <label>Select Week Start (Monday):</label>
        <input 
          type="date" 
          value={weekStart} 
          onChange={handleWeekStartChange} 
          className='weekStartPicker'
        />
        <button onClick={findWeekAppointments} disabled={!selectedType || !selectedTime || !weekStart} className='btn_NextAv'>
          Find Appointments for a Week
        </button>
      </div>

      {weeklyAppointments.length > 0 && (
        <div>
          <h3>Available Appointments for the Week:</h3>
          <div className='appointment-buttons'>
            {weeklyAppointments.map((appt, index) => (
              <button
                key={index}
                onClick={() => setSelectedAppointment(appt.iso)} // Store ISO format for booking
                className={`appointment-button ${selectedAppointment === appt.iso ? 'selected' : ''}`}
              >
                {appt.display}
              </button>
            ))}
          </div>
          {selectedAppointment && (
            <button onClick={bookAppointment} className='btn_NextAv'>Book Now</button>
          )}
        </div>
      )}
    </div>
  );
};

export default NextAvApps;

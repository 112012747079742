import React, { useState, useEffect } from 'react';
import './physioContent.css';

const NewAlerts = ({ patient }) => {
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (patient && patient.length > 0) {
      const currentPatient = patient[0];
      setAlert(currentPatient.Alert || null); // Set alert to the object or null if not present
    }
  }, [patient]); // Re-run this effect whenever `patient` changes

  return (
    <div className="container">
      <h2>New Alert</h2>
      {alert ? (
        <div key={alert.alertID} className="alert-container">
          <p><strong>Alert Message:</strong> {alert.alertMessage}</p>
          <p><strong>Timestamp:</strong> {new Date(alert.alertTimestamp).toLocaleString()}</p>
          <p><strong>Type:</strong> {alert.alertType}</p>
          <p><strong>Body Part:</strong> {alert.alertBodyPart}</p>
        </div>
      ) : (
        <p>No new alerts available.</p>
      )}
    </div>
  );
};

export default NewAlerts;

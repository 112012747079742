import React from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import CreateRole from "../mainContent/createProfile/createRoleHome";
import PrivacyPolicyContent from '../mainContent/privacyPolicy';
import HealthDisclaimer from "../mainContent/healthDisclaimer";

const CreateRoleLayout = ({onSubmit}) => {
    const navigate = useNavigate();
    const handleLinkClick = (page) => {
        navigate(page);
      };
    return(
        <div>
            <Routes>
                <Route path="*" element={<CreateRole onSubmit={onSubmit} onlinkClick={handleLinkClick}/>}/>
                <Route path="privacy-policy" element={<PrivacyPolicyContent/>} />
                <Route path="health-disclaimer" element={<HealthDisclaimer />} />
            </Routes>
            {/* <CreateRole onSubmit={onSubmit} onlinkClick={handleLinkClick}/> */}
        </div>
    )
}

export default CreateRoleLayout;
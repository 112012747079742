import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import "./createProfile.css";

const CreatephysioProfile = ({ onProfileCreated, dataID }) => {
  const [name, setName] = useState("");
  const [businessKey, setBusinessKey] = useState("");
  const [licenceKey, setLicenceKey] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  useEffect(() => {
    // Array of image paths
    const images = [
      require("../../images/background1.jpg"),
      require("../../images/background2.jpg"),
      require("../../images/background3.jpg"),
      require("../../images/background4.jpg"),
      require("../../images/background5.jpg"),
    ];

    // Select a random image
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []);

  const fetchBusinessData = async () => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/appCosts/get?dataID=${businessKey}`
      );
      const data = await response.json();
      console.log(data);
  
      // Find the first licenceKey without a practitioner
      const firstEmptyLicence = data.practitionerLicences.find(
        (licence) => !licence.practitioner
      );
  
      if (firstEmptyLicence) {
        setLicenceKey(firstEmptyLicence.licenceKey)
        await updatePractitionerLicence(firstEmptyLicence.licenceKey)
        console.log("First empty licenceKey:", firstEmptyLicence.licenceKey);
      } else {
        console.log("All licences have practitioners.");
      }
    } catch (error) {
      console.log("Error getting business details:", error);
    }
  };

  const updatePractitionerLicence = async (licence) => {
    const body = {
      dataID : businessKey,
      practitionerID: dataID,
      licenceKey: licence
    }

    try{
      const response = await fetch("https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/updateLicence",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }
      );

      if (!response.ok){
        throw new Error("Failed to update practitioner licence")
      }

      console.log(response)
    }
    catch (error){
      console.log(error)
    }
  }
  

  const createNameRelation = async (nameRelationData) => {
    const response = await fetch(
      "https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/name-Relation/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nameRelationData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create name relation");
    }

    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(dataID)
    const nameRelationData = {
      dataID: dataID,
      name: CryptoJS.AES.encrypt(name, encryptionKey).toString(),
    };

    try {
      // Call the function to create name relation
      const CNRResponse = await createNameRelation(nameRelationData);
      console.log("Name relation: ", CNRResponse);
      fetchBusinessData();

      // Assuming profile creation is successful, you call onProfileCreated
      if (onProfileCreated) {
        onProfileCreated();
      }
    } catch (error) {
      console.error("Error creating profile:", error);
    }
  };

  return (
    <div
      className="mainContainer"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className=" mainContent">
        <h2>Create Profile</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Business key:
              <input
                type="text"
                value={businessKey}
                onChange={(e) => setBusinessKey(e.target.value)}
              />
            </label>
          </div>
          <div>
            <button type="submit">Create Profile</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatephysioProfile;

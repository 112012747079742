import React, { useEffect, useState } from "react";
import './practice.css';

const AppointmentManagement = ({ clKey, busID }) => {
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentCosts, setAppointmentCosts] = useState([]); // Store fetched appointment costs
  const [updatedAppointments, setUpdatedAppointments] = useState([]); // Store the combined types with costs
  const [editingIndex, setEditingIndex] = useState(null); // Track which appointment type is being edited
  const [editingPrices, setEditingPrices] = useState({}); // Track price changes for each appointment type

  // Fetch appointment costs and types only once
  useEffect(() => {
    const fetchData = async () => {
      await fetchAppointmentCosts();  // Fetch costs
      await handleListAppointmentTypes();   // Fetch types
    };
    fetchData();
  }, []);  // Empty dependency array ensures this runs only once when component mounts

  // Fetch the business appointment costs when component mounts
  const fetchAppointmentCosts = async () => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/appCosts/get?dataID=${busID}`
      );
      const data = await response.json();
      console.log("Fetched appointment costs:", data.Appointments);
      setAppointmentCosts(data.Appointments); // Store the fetched appointment costs
    } catch (error) {
      console.log("Error getting business details:", error);
    }
  };

  const handleListAppointmentTypes = async () => {
    const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/getAppTypes`, {
      method: "GET",
      headers: {
        'x-api-key': clKey,
      },
    });
    const data = await resp.json();
    console.log("Fetched appointment types:", data.appointment_types);
    setAppointmentTypes(data.appointment_types); // Store appointment types without cost initially
  };

  // This useEffect will trigger once both appointment types and costs are fetched
  useEffect(() => {
    if (appointmentCosts.length > 0 && appointmentTypes.length > 0 && updatedAppointments.length === 0) {
      console.log("Populating appointment types with costs...");
      
      // Map appointment types and assign matching costs from appointmentCosts
      const appointmentsWithCost = appointmentTypes.map((type) => {
        const matchingAppointment = appointmentCosts.find((appt) => {
          return appt.name.toLowerCase().trim() === type.name.toLowerCase().trim();
        });

        return {
          ...type,
          cost: matchingAppointment ? matchingAppointment.cost : "£40", // Default cost if not found
        };
      });

      console.log("Updated appointment types with costs:", appointmentsWithCost);
      setUpdatedAppointments(appointmentsWithCost); // Store the updated appointment types with costs
    }
  }, [appointmentCosts, appointmentTypes]);  // Only run when both appointmentCosts and appointmentTypes are available

  // Function to handle price changes in the input fields
  const handlePriceChange = (index, newPrice) => {
    setEditingPrices((prev) => ({
      ...prev,
      [index]: newPrice, // Store new price for the corresponding appointment type
    }));
  };

  // Function to save the updated prices
  const handleSavePrice = async (index) => {
    const updatedList = updatedAppointments.map((type, i) => {
      if (i === index) {
        return { ...type, cost: editingPrices[index] || type.cost }; // Update the cost
      }
      return type;
    });

    setUpdatedAppointments(updatedList); // Update the state with the new prices
    setEditingIndex(null); // Close editing interface after saving

    // Prepare the payload for the API call
    const payload = {
      dataID: busID,
      appointments: updatedList.map((type) => ({
        name: type.name,
        cost: type.cost,
      })),
    };

    // Make the API call to update appointment costs
    try {
      const response = await fetch(
        "https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/appCosts/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload), // Send the payload as a JSON string
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update appointment costs");
      }

      console.log("Appointment costs updated successfully");
    } catch (error) {
      console.error("Error updating appointment costs:", error);
    }
  };

  // Function to toggle the edit mode
  const handleEditClick = (index) => {
    setEditingIndex(index); // Set the index of the appointment type to be edited
    setEditingPrices((prev) => ({
      ...prev,
      [index]: updatedAppointments[index].cost, // Initialize editing price with the current cost
    }));
  };

  return (
    <div className="container">
      <h4>Appointment Management</h4>
      <ul>
        {updatedAppointments.map((type, index) => (
          <li key={index}>
            {type.name} - 
            {editingIndex === index ? (
              // Show input field when in editing mode
              <>
                <input
                  type="text"
                  value={editingPrices[index] || type.cost}
                  onChange={(e) => handlePriceChange(index, e.target.value)}
                />
                <button onClick={() => handleSavePrice(index)}>Save</button>
                <button onClick={() => setEditingIndex(null)}>Cancel</button>
              </>
            ) : (
              // Show regular price and Edit button when not editing
              <>
                {type.cost || "£40"}  {/* Fallback value if cost isn't populated */}
                <button onClick={() => handleEditClick(index)}>Edit</button>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AppointmentManagement;

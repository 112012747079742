import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "../mainContent/coach/header";
import Profilecontent from "../mainContent/coach/profile/profilepage";
import PhysioMainContent from "../mainContent/coach/home/physioMainContent";
import Training from "../mainContent/coach/training/trainingContent";
import About from "../mainContent/about/about";
import Footer from '../mainContent/footer'
import PrivacyPolicyContent from '../mainContent/privacyPolicy'
import './layouts.css'

const CoachLayout = (dataID) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [tier, setTier] = useState("base");
  const [colorMain, setColorMain] = useState("#007bff");
  const [colorSecond, setColorSecond] = useState("#fff");
  const navigate = useNavigate();


  useEffect(() => {
    const fetchTier = async () => {
      try{
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/tier?dataID=${dataID.dataID}`
        );
        const data = await response.json();
        console.log(data.tier)
        const tierStr = data.tier.toString();
        setTier(tierStr)
      }
      catch (error){
        console.error("Error fetching tier:", error);
      }
    }

    fetchTier();
  })

  const handleLinkClick = (page) => {
    navigate(page); // Navigate to the appropriate route
  };

  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);
    if (patient && patient.ptID) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/get?userID=${patient.ptID}`
        );
        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    }
  };

  const refreshPTData = async () => {
    if (selectedPatient && selectedPatient.ptID) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/get?userID=${selectedPatient.ptID}`
        );
        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    }
  };

  return (
    <div>
      <Header 
      onLinkClick={handleLinkClick} 
      tier={tier}/>
      <div className="content" style={{ backgroundImage: `url(/backgroundSq.svg)`}} >
        <Routes>
          <Route
            path="home"
            element={
              <PhysioMainContent
                handlePatientSelect={handlePatientSelect}
                selectedPatient={selectedPatient}
                patientData={patientData}
                refreshPTData={refreshPTData}
                dataID={dataID}
              />
            }
          />
          <Route
            path="training"
            element={
              <Training
                handlePatientSelect={handlePatientSelect}
                selectedPatient={selectedPatient}
                patientData={patientData}
                refreshPTData={refreshPTData}
                dataID={dataID}
              />
            }
          />
          <Route path="about" element={<About />} />
          <Route path="profile" element={<Profilecontent dataID={dataID} />} />
          <Route path="privacy-policy" element={<PrivacyPolicyContent/>} />
        </Routes>
      </div>
      <Footer onLinkClick={handleLinkClick}/>
    </div>
  );
};

export default CoachLayout;

import React from 'react';

const PracticeAlerts = () => {
  const exampleAlerts = [
    {
      title: 'New Clinic Opening Hours',
      message: 'Starting November 15th, our practice will extend operating hours from 8:00 AM to 8:00 PM on weekdays.',
      date: 'November 1st, 2024',
    },
    {
      title: 'Winter Promotion',
      message: 'Get 15% off your first session in December. Book your spot now!',
      date: 'October 30th, 2024',
    },
    {
      title: 'Physio Workshop',
      message: 'Join our free workshop on back pain management on November 20th at 5:00 PM.',
      date: 'October 25th, 2024',
    },
  ];

  return (
    <div style={styles.alertsContainer}>
      <header style={styles.header}>
        <h1>Practice Alerts</h1>
        <p>Stay informed with the latest updates and important notices.</p>
      </header>
      <section style={styles.alertList}>
        {exampleAlerts.map((alert, index) => (
          <div key={index} style={styles.alertItem}>
            <h2 style={styles.alertTitle}>{alert.title}</h2>
            <p style={styles.alertMessage}>{alert.message}</p>
            <p style={styles.alertDate}>📅 {alert.date}</p>
          </div>
        ))}
      </section>
    </div>
  );
};

const styles = {
  alertsContainer: {
    padding: '20px',
    backgroundColor: '#ffe5e5',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  },
  header: {
    borderBottom: '2px solid #d9534f',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  alertList: {
    marginBottom: '20px',
  },
  alertItem: {
    backgroundColor: '#ffffff',
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '15px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  alertTitle: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  alertMessage: {
    fontSize: '1em',
    marginBottom: '10px',
  },
  alertDate: {
    fontSize: '0.8em',
    color: '#555',
  },
};

export default PracticeAlerts;

import React, { useEffect, useState } from "react";
import PractitionerManagement from "./practitionerManagement";
import AppointmentManagement from "./appointmentManagement";
import CaseManage from "./caseManagement";
import CoffeeAnimation from '../../../coffee';

const PracticeManagement = ({ dataID }) => {
  const [BusinessID, setBusinessID] = useState("");
  const [athBusID, setAthBusID] = useState("");
  const [clApiKey, setCLAPIKey] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Track if data is loaded

  const getAthBusID = async () => {
    console.log(dataID.dataID);
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/getID?dataID=${dataID.dataID}`
      );
      const data = await response.json();

      console.log(data.bisID);
      setAthBusID(data.bisID);

      if (data.bisID) {
        await getCliAPIKey(data.bisID); // Wait for getCliAPIKey to finish
      }
    } catch (error) {
      console.error("Error fetching business ID:", error);
    }
  };

  const getCliAPIKey = async (busID) => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/getCLKey?dataID=${busID}`
      );
      const kdata = await response.json();

      console.log(kdata.ClAPIKey);
      setCLAPIKey(kdata.ClAPIKey);

      if (kdata.ClAPIKey) {
        await getBusinessID(kdata.ClAPIKey); // Wait for getBusinessID to finish
      } else {
        console.log("API Key not found");
      }
    } catch (error) {
      console.error("Error fetching API key:", error);
    }
  };

  const getBusinessID = async (apikey) => {
    try {
      const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/businesses`, {
        method: "GET",
        headers: {
          "x-api-key": apikey, // Pass the API key in custom headers
        },
      });
      const data = await resp.json();
      console.log("Cl Bus ID:", data);
      setBusinessID(data.businesses[0]?.id || null);
      setIsDataLoaded(true); // Mark data as fully loaded
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getAthBusID();
    }
    fetchData();
  }, []);

  return (
    <div>
      <h2>Practice Management</h2>
      {isDataLoaded ? (
        <>
          <PractitionerManagement bisID={BusinessID} clKey={clApiKey} />
          <CaseManage clKey={clApiKey} />
          <AppointmentManagement busID={athBusID} clKey={clApiKey}/>
        </>
      ) : (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p>Loading data...</p>
          <CoffeeAnimation /> {/* Show coffee animation */}
        </div>
      )}
    </div>
  );
};

export default PracticeManagement;

import React from "react";
import PracticeLicences from "./practiceLicences";
import APIKeyManager from "./keyManager";

const LicenceManager = ({dataID}) => {

    return(
        <div>
            <h2>Licence Manager</h2>
            <PracticeLicences />
            <APIKeyManager dataID={dataID}/>
        </div>
    )
}

export default LicenceManager;
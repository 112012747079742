import { Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from '../login/LoginHome';
import AboutAppPage from '../mainContent/aboutApp'
import Header from '../login/loginHeader'

const LoginLayout = () => {
  const navigate = useNavigate(); 
  const handleLinkClick = (page) => {
    navigate(page);  // Navigate to the appropriate route
  };

  return (
    <div>
      <Header onLinkClick={handleLinkClick}/>
      <Routes>
        {/* Default login page */}
        <Route path="home" element={<LoginPage />} />
        <Route path="about" element={<AboutAppPage />} />
        {/* You can add more nested routes here if needed */}
      </Routes>
    </div>
  );
};

export default LoginLayout;

import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import "./createProfile.css";

const CreateManagerProfile = ({ onProfileCreated, dataID }) => {
  const [name, setName] = useState("");
  const [bname, setBName] = useState("");
  const [licenceKey, setLicenceKey] = useState("");
  const [clinikolicenceKey, setClinikoLicenceKey] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  useEffect(() => {
    // Array of image paths
    const images = [
      require("../../images/background1.jpg"),
      require("../../images/background2.jpg"),
      require("../../images/background3.jpg"),
      require("../../images/background4.jpg"),
      require("../../images/background5.jpg"),
    ];

    // Select a random image
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []);

  const encryptField = (value, encryptionKey) => {
    return CryptoJS.AES.encrypt(value, encryptionKey).toString();
  }

  const checkLicence = async () => {
  
    try {
      const response = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/licence/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ licenceKey: licenceKey }), 
      });
  
      if (!response.ok) {
        console.log(response)
        throw new Error('Something went wrong');
      }
  
      const data = await response.json();
      return data
    } catch (err) {
      console.log(err.message);
    }
  };

  const UseLicence = async () => {
    try {
      const response = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/licence/use', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ licenceKey: licenceKey }),
      });
  
      if (!response.ok) {
        console.log(response)
        throw new Error('Something went wrong');
      }
  
      const data = await response.json();
      return data
    } catch (err) {
      console.log(err.message);
    }
  }

  const createBusinessLicence = async () => {

    const encryptionKey = process.env.REACT_APP_ENCRYPTION_LKEY;
    try {
      const response = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          BusinessName: bname, 
          licencekey: licenceKey, 
          userID: dataID,
          clinikoKey: encryptField(clinikolicenceKey, encryptionKey)

        }),
      });
  
      if (!response.ok) {
        console.log(response);
        throw new Error('Something went wrong');
      }
  
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err.message);
      return null; // Return null or handle the error appropriately
    }
  };

  const createNameRelation = async (nameRelationData) => {
    const response = await fetch(
      "https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/name-Relation/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nameRelationData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create name relation");
    }

    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let checkResp = "";
    let useResp = "";
    checkResp = await checkLicence();
    console.log(checkResp)
    if (checkResp){
      useResp = await UseLicence();
      console.log(useResp)
    }
    if (checkResp && useResp){
      createBusinessLicence();
    }
    const nameRelationData = {
      dataID: dataID,
      name: CryptoJS.AES.encrypt(name, encryptionKey).toString(),
    };
    const CNRResponse = await createNameRelation(nameRelationData);
    if (onProfileCreated) {
      onProfileCreated();
    }
  };

  const onButtonClick = async () =>{
    let checkResp = "";
    let useResp = "";
    checkResp = await checkLicence();
    console.log(checkResp)
    if (checkResp){
      useResp = await UseLicence();
      console.log(useResp)
    }
    if (checkResp && useResp){
      createBusinessLicence();
    }
  }

  return (
    <div
      className="mainContainer"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className=" mainContent">
        <h2>Create Profile</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Business Name:
              <input
                type="text"
                value={bname}
                onChange={(e) => setBName(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Licence key:
              <input
                type="text"
                value={licenceKey}
                onChange={(e) => setLicenceKey(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label>
              Cliniko Licence key:
              <input
                type="text"
                value={clinikolicenceKey}
                onChange={(e) => setClinikoLicenceKey(e.target.value)}
              />
            </label>
          </div>
          <div>
            <button onClick={onButtonClick}>Create Profile</button>
            {/* <button type="submit">Create Profile</button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateManagerProfile;

import React, { useState } from 'react';
import './physioContent.css';
import NewAppointment from './newAppointment';

const PhysioReportNewIssue = ({userID}) => {
  const [bodyPart, setBodyPart] = useState('');
  const [comment, setComment] = useState('');

  const patientName = 'Ted Heywood'
  const practitionerName = 'Ted Heywood'

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Prepare the data to be sent in the request
    const data = {
      alertBodyPart: bodyPart,
      alertID: "1", // You might need to generate or fetch this ID dynamically
      alertMessage: comment,
      alertTimestamp: new Date().toISOString(), // Use current timestamp
      alertType: "injury" // You might want to make this dynamic or configurable
    };

    // Define the API URL
    const userid = userID.dataID
    const apiUrl = `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/updateAlert?userId=patient[0].${userid}`;

    try {
      // Send the PUT request
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log('Success:', result);

      // Reset form after submission
      setBodyPart('');
      setComment('');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container">
      <h2>Report New Issue</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="bodyPart">Select Body Part:</label>
          <select
            id="bodyPart"
            value={bodyPart}
            onChange={(e) => setBodyPart(e.target.value)}
          >
            <option value="">--Please choose an option--</option>
            <option value="Head">Head</option>
            <option value="Shoulder">Shoulder</option>
            <option value="Arm">Arm</option>
            <option value="Elbow">Elbow</option>
            <option value="Wrist">Wrist</option>
            <option value="Hand">Hand</option>
            <option value="Chest">Chest</option>
            <option value="Abdomen">Abdomen</option>
            <option value="Hip">Hip</option>
            <option value="Thigh">Thigh</option>
            <option value="Knee">Knee</option>
            <option value="Shin">Shin</option>
            <option value="Ankle">Ankle</option>
            <option value="Foot">Foot</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="comment">Comment:</label>
          <textarea
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows="4"
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      <NewAppointment userID={userID}/>
    </div>
  );
}

export default PhysioReportNewIssue;

import React from "react";
import './aboutApp.css';

const AboutAppPage = ({ onLinkClick }) => {
    return (
        <div className="about-app-container">
            <h2>About This App</h2>
            <p>
                Welcome to AthlioConnect! This application is a comprehensive platform designed to enhance the
                rehabilitation experience for both patients and practitioners in the pursuit of performance.
            </p>
            <p>
                AthlioConnect provides a centralised space where all programming,
                videos, and resources are easily accessible. Unlike traditional
                solutions that rely on generic video banks, AthlioConnect enables
                delivering patient-specific videos tailored to individual patient
                needs.
            </p>
            <h3>Key Features</h3>
            <ul>
                <li>Portal for each role - athlete, physio, and pt</li>
                <li>Link to your business or use as an individual practitioner</li>
                <li>As an athlete or patient, this will cost you nothing!</li>
                <li>Physio, PT, and healthcare providers please get in contact for licensing</li>
            </ul>
            <h3>Get Started</h3>
            <p>
                Ready to experience the benefits? Sign up today and join thousands of satisfied users who have already transformed their lives with AthlioConnect.
            </p>

            <h3>Beta</h3>
            <p>
                This app is in beta development. There will be bugs, but please be kind and let me know where they are.
            </p>

            <h3>Privacy Policy</h3>
            <p>
                For privacy policy please follow this link: <a href="#" onClick={() => onLinkClick("privacy")}>Privacy Policy</a>
            </p>
        </div>
    );
}

export default AboutAppPage;

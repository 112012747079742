import React, { useState, useEffect } from 'react';
import './TodaysTrainingPlan.css';

const TodaysTrainingPlan = ({ userID }) => {
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedWorkout, setEditedWorkout] = useState(null);
  const [workoutData, setWorkoutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userid = userID.dataID;
        const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athlete/weeklyProgram?userID=${userid}`);
        const data = await response.json();

        // Assuming data.body is an object, no need to parse
        if (data && data.M) {
          setWorkoutData(data.M); 
        } else {
          throw new Error("Invalid data format");
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [userID]);

  useEffect(() => {
    if (isEditMode && workoutData) {
      const todaysDay = daysOfWeek[currentDayIndex];
      const todaysWorkoutData = workoutData[todaysDay]?.M;
      if (todaysWorkoutData) {
        setEditedWorkout({
          DayType: todaysWorkoutData.DayType.S,
          Exercises: todaysWorkoutData.ExerciseList.L.map(exercise => exercise.S)
        });
      }
    }
  }, [isEditMode, workoutData, currentDayIndex]);

  const correctOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!workoutData) {
    return <div>No workout data available.</div>;
  }

  const daysOfWeek = Object.keys(workoutData).sort((a, b) => correctOrder.indexOf(a) - correctOrder.indexOf(b));

  const handleNextDay = () => {
    setCurrentDayIndex((prevIndex) => (prevIndex + 1) % daysOfWeek.length);
    setIsEditMode(false);
    setEditedWorkout(null);
  };

  const todaysDay = daysOfWeek[currentDayIndex];
  const todaysWorkoutData = workoutData[todaysDay]?.M;

  if (!todaysWorkoutData) {
    return <div>No workout data available for {todaysDay}.</div>;
  }

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDayTypeChange = (e) => {
    setEditedWorkout((prev) => ({ ...prev, DayType: e.target.value }));
  };

  const handleInputChange = (index, e) => {
    const newExercises = [...editedWorkout.Exercises];
    newExercises[index] = e.target.value;
    setEditedWorkout((prev) => ({ ...prev, Exercises: newExercises }));
  };

  const addExercise = () => {
    const newExercises = [...editedWorkout.Exercises, 'New Exercise'];
    setEditedWorkout((prev) => ({ ...prev, Exercises: newExercises }));
  };

  const deleteExercise = (index) => {
    const newExercises = [...editedWorkout.Exercises];
    newExercises.splice(index, 1);
    setEditedWorkout((prev) => ({ ...prev, Exercises: newExercises }));
  };

  const saveChanges = async () => {
    workoutData[todaysDay].M.ExerciseList.L = editedWorkout.Exercises.map(exercise => ({ S: exercise }));
    workoutData[todaysDay].M.DayType.S = editedWorkout.DayType;
    setIsEditMode(false);

    const payload = {
      UserID: userID.dataID, // Using userID passed as a prop
      UserData: {
        [todaysDay]: {
          DayType: editedWorkout.DayType,
          ExerciseList: editedWorkout.Exercises
        }
      }
    };

    try {
      const response = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athlete/updateWeeklyProgram', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Failed to save changes');
      }
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={`todays-training-plan ${isEditMode ? 'edit-mode' : ''}`}>
      <h2>Today's Training Plan</h2>
      {isEditMode && (
        <button onClick={saveChanges}>Save Changes</button>
      )}
      <p><strong>{todaysDay}</strong>: {isEditMode ? (
        <input
          type="text"
          value={editedWorkout?.DayType || ''}
          onChange={handleDayTypeChange}
        />
      ) : (
        todaysWorkoutData.DayType.S
      )}</p>
      <div className="exercises-container">
        {isEditMode ? (
          <ul>
            {editedWorkout?.Exercises.map((exercise, index) => (
              <li key={index}>
                <input
                  type="text"
                  value={exercise}
                  onChange={(e) => handleInputChange(index, e)}
                />
                <button onClick={() => deleteExercise(index)}>Delete</button>
              </li>
            ))}
            <li>
              <button onClick={addExercise}>Add Exercise</button>
            </li>
          </ul>
        ) : (
          <ul>
            {todaysWorkoutData.ExerciseList.L.map((exercise, index) => (
              <li key={index}>{exercise.S}</li>
            ))}
          </ul>
        )}
      </div>
      <div className="buttons-container">
        <button onClick={handleNextDay}>Next Day</button>
        <button onClick={toggleEditMode}>
          {isEditMode ? 'Exit Edit Mode' : 'Edit Training Program'}
        </button>
      </div>
    </div>
  );
};

export default TodaysTrainingPlan;

import React, { useState } from "react";
import "./header.css";
import LogoutButton from "../../login/logout";
import logo from "../../images/logoLightnoDigilab.png";

const Header = ({ onLinkClick, tier, colorMain, colorSecond }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const tierLabel = tier === "1" ? "Manager" : tier === "2" ? "Manager Plus" : "Manager Base";

  // Apply styles conditionally for tier 2
  const headerStyle = tier === "2" ? { backgroundColor: colorSecond } : {};
  const listItemStyle = tier === "2" ? { color: colorMain } : {};

  return (
    <header className="headerPhysio" style={headerStyle}>
      <div className="logo-placeholder">
        <img src={logo} alt="AthlioConnect Logo" className="logo" />
      </div>
      <h1 className="header-title" style={listItemStyle}>{tierLabel}</h1>

      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <nav className="desktop-nav">
        <ul>
          <li style={listItemStyle}>
            <a onClick={() => onLinkClick("home")} style={listItemStyle}>Home</a>
          </li>
          <li style={listItemStyle}>
            <a onClick={() => onLinkClick("training")} style={listItemStyle}>Training</a>
          </li>
          <li style={listItemStyle}>
            <a onClick={() => onLinkClick("about")} style={listItemStyle}>About</a>
          </li>
          <li style={listItemStyle}>
            <a onClick={() => onLinkClick("profile")} style={listItemStyle}>Profile</a>
          </li>

          <li className="dropdown" style={listItemStyle}>
            <a className="dropdown-toggle" onClick={toggleDropdown} style={listItemStyle}>
              Management
            </a>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li style={listItemStyle}>
                  <a onClick={() => onLinkClick("practice")} style={listItemStyle}>Practice management</a>
                </li>
                <li style={listItemStyle}>
                  <a onClick={() => onLinkClick("licences")} style={listItemStyle}>Licence management</a>
                </li>
              </ul>
            )}
          </li>

          <li>
            <LogoutButton />
          </li>
        </ul>
      </nav>

      <div className={isMobileMenuOpen ? "mobile-menu mobile-menu-open" : "mobile-menu"}>
        <ul>
          <li style={listItemStyle}>
            <a onClick={() => { onLinkClick("home"); closeMobileMenu(); }} style={listItemStyle}>Home</a>
          </li>
          <li style={listItemStyle}>
            <a onClick={() => { onLinkClick("training"); closeMobileMenu(); }} style={listItemStyle}>Training</a>
          </li>
          <li style={listItemStyle}>
            <a onClick={() => { onLinkClick("about"); closeMobileMenu(); }} style={listItemStyle}>About</a>
          </li>
          <li style={listItemStyle}>
            <a onClick={() => { onLinkClick("profile"); closeMobileMenu(); }} style={listItemStyle}>Profile</a>
          </li>

          <li style={listItemStyle}>
            <a onClick={toggleDropdown} className="dropdown-toggle" style={listItemStyle}>Management</a>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li style={listItemStyle}>
                  <a onClick={() => { onLinkClick("practice"); closeMobileMenu(); }} style={listItemStyle}>Practice management</a>
                </li>
                <li style={listItemStyle}>
                  <a onClick={() => { onLinkClick("licences"); closeMobileMenu(); }} style={listItemStyle}>Licence management</a>
                </li>
              </ul>
            )}
          </li>

          <li>
            <LogoutButton />
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';

const HealthDisclaimer = () => {
    return (
        <div className="health-disclaimer">
            <h2>Health Disclaimer</h2>
            <p>
                The information provided by this application is intended for general informational purposes only. 
                It is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the 
                advice of your physician or other qualified health provider with any questions you may have regarding 
                a medical condition or treatment. 
            </p>
            <p>
                Never disregard professional medical advice or delay in seeking it because of something you have read 
                on this application. If you think you may have a medical emergency, call your doctor or emergency 
                services immediately.
            </p>
            <p>
                By using this application, you acknowledge that you have read and understood this disclaimer.
            </p>
        </div>
    );
};

export default HealthDisclaimer;

import React, { useEffect, useState } from 'react';
import './physioContent.css';
import CryptoJS from 'crypto-js';

const PatientDropdown = ({ dataID, onSelect }) => {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');

  const decryptField = (encryptedValue, encryptionKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const dataid = dataID.dataID;
        const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/getPTs?physioID=${dataid}`);
        const data = await response.json();
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

        const decryptedData = data.map(patient => ({
          ...patient,
          ptName: decryptField(patient.ptName, encryptionKey)
        }));

        setPatients(decryptedData);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    fetchPatients();
  }, [dataID]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChange = (event) => {
    const selectedPatient = patients.find(patient => patient.ptID === event.target.value);
    setSelectedPatient(event.target.value);
    onSelect(selectedPatient);
  };

  // Filter patients based on search term
  const filteredPatients = patients.filter(patient =>
    patient.ptName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="form-group">
      <label htmlFor="searchInput">Select Patient:</label>
      <input
        type="text"
        id="searchInput"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Type to filter"
        className="search-input"
      />

      <select
        id="patientSelect"
        value={selectedPatient}
        onChange={handleChange}
        className="dropdownd"
      >
        <option value="">--Please choose a patient--</option>
        {filteredPatients.map((patient) => (
          <option key={patient.ptID} value={patient.ptID}>
            {patient.ptName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PatientDropdown;

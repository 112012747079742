// src/components/LoginPage.jsx
import React, { useState, useEffect } from "react";
import LoginButton from "./loginAuth";
import Logo from "../images/logoLight.png";
import './login.css'

const LoginPage = () => {
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    // Array of image paths
    const images = [
      require("../images/background1.jpg"),
      require("../images/background2.jpg"),
      require("../images/background3.jpg"),
      require("../images/background4.jpg"),
      require("../images/background5.jpg"),
    ];

    // Select a random image
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []);

  const handleTestClick = async () => {
    const apiUrl = "http://localhost:5000/api/businesses";
      const resp = await fetch(apiUrl, {
        method: "GET"
      });
      const data = await resp.json();
      console.log(data)
      const businessId = data.businesses[0].id; // Assuming you're grabbing the first business, modify as needed
      return businessId
  }

  return (
    <div
      className="mainContainer"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="mainContent">
        <div className="logoContainer">
          <img src={Logo} alt="athlioconnect logo" className="Loginlogo"></img>
        </div>

        <LoginButton />
      </div>
    </div>
  );
};

export default LoginPage;

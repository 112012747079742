import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './practice.css'

const PractitionerManagement = ({clKey, BisID}) => {
  const [practitioners, setPractitioners] = useState([]);

  // const getPractitioners = async () => {
  //   try {
  //     const resp = await fetch(`http://localhost:5000/api/Managementpractitioners`, {
  //       method: "GET",
  //       headers: {
  //         'x-api-key': clKey, 
  //       },
  //     });

  //     const data = await resp.json();
  //     setPractitioners(data.practitioners);
  //   } catch (error) {
  //     console.error("Error fetching practitioners:", error);
  //   }
  // };
  const getPractitioners = async () => {
    try {
      const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/Managementpractitioners`, {
        method: "GET",
        headers: {
          'x-api-key': clKey, 
        },
      });

      const data = await resp.json();
      setPractitioners(data.practitioners);
    } catch (error) {
      console.error("Error fetching practitioners:", error);
    }
  };

  useEffect(() => {
    async function getData() {

    await getPractitioners();
    }

    getData();
    
  }, []);

  return (
    <div className="containerr">
      <h4>Practitioners</h4>
      <ul>
        {practitioners.map((practitioner, index) => (
          <li key={index}>
            {practitioner.label}
            <ul>
              {practitioner.appointmentTypes.length > 0 ? (
                practitioner.appointmentTypes.map((type, idx) => (
                  <li key={idx}>{type.name}</li>
                ))
              ) : (
                <li>No appointment types available</li>
              )}
            </ul>
            {/* Link to the practitioner's detailed view */}
            {/* <Link to={`/practice/practitioner/${practitioner.id}`}>
              <button>View Practitioner</button>
            </Link> */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PractitionerManagement;

import React, { useEffect, useState } from "react";
import "../practice/practice.css";
import './licence.css';

const APIKeyManager = ({ dataID }) => {
  // State to toggle visibility of keys
  const [showApiKey, setShowApiKey] = useState(false);
  const [showBusinessKey, setShowBusinessKey] = useState(false);
  const [athBusId, setAthBusID] = useState("");
  const [clAPIKey, setClAPIKey] = useState("");

  // Toggle functions for the keys
  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const toggleBusinessKeyVisibility = () => {
    setShowBusinessKey(!showBusinessKey);
  };

  const getAthBusID = async () => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/getID?dataID=${dataID.dataID}`
      );
      const data = await response.json();
      setAthBusID(data.bisID);
      return data.bisID;
    } catch (error) {
      console.error("Error fetching business ID:", error);
    }
  };

  const fetchAppointmentCosts = async (busID) => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/appCosts/get?dataID=${busID}`
      );
      const data = await response.json();
      setClAPIKey(data.ClAPIKey);
    } catch (error) {
      console.log("Error getting business details:", error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const bisID = await getAthBusID();
      await fetchAppointmentCosts(bisID);
    };

    if (athBusId === "") {
      getData();
    }
  }, [athBusId]);

  // Function to copy the API key to clipboard
  const copyToClipboard = (key) => {
    navigator.clipboard.writeText(key).then(
      () => {
        alert("Copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  return (
    <div className="containerr">
      <h2>API Keys</h2>

      <div className="key-section">
        <h3>API Key</h3>
        <p>{showApiKey ? clAPIKey : "************"}</p>
        <button onClick={toggleApiKeyVisibility} className="reveal-button">
          {showApiKey ? "Hide Key" : "Reveal Key"}
        </button>
        <button onClick={() => copyToClipboard(clAPIKey)} className="copy-button">
          Copy to Clipboard
        </button>
      </div>

      <div className="key-section">
        <h3>Business Key</h3>
        <p>{showBusinessKey ? athBusId : "************"}</p>
        <button onClick={toggleBusinessKeyVisibility} className="reveal-button">
          {showBusinessKey ? "Hide Key" : "Reveal Key"}
        </button>
        <button onClick={() => copyToClipboard(athBusId)} className="copy-button">
          Copy to Clipboard
        </button>
      </div>
    </div>
  );
};

export default APIKeyManager;

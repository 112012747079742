import React, { useEffect, useState } from 'react';
import './VideoSelector.css'; // Importing the CSS file for styles
import { FaPlay, FaArchive } from 'react-icons/fa'; // Importing the play icon from react-icons
import { vi } from 'date-fns/locale';

const VideoSelector = ({ userId, reload, onSelectVideo }) => {
  const [videoList, setVideoList] = useState([]);
  const [archivedVideoList, setArchivedVideoList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingArchived, setLoadingArchived] = useState(false);
  const [error, setError] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [showArchivedVideos, setShowArchivedVideos] = useState(false); // New state for showing archived videos

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/listVideos?userId=${userId}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }
        const videos = await response.json();
        setVideoList(videos);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchVideos();
  }, [userId, reload]);

  const fetchVideoUrl = async (videoName) => {
    try {
      const fileName = `userVideos/${userId}+${videoName}`;
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/playVideos?fileName=${encodeURIComponent(fileName)}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch video URL');
      }
      const { url } = await response.json();
      setVideoUrl(url);
    } catch (error) {
      console.error('Error fetching video URL:', error);
      setError(error.message);
    }
  };

  const archiveVideo = async (videoName) => {
    try {
      const fileName = `${userId}+${videoName}`;
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/archive?fileName=${encodeURIComponent(fileName)}`, 
        {
          method: 'POST',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to archive video');
      }
      // Optionally remove the video from the list after archiving
      setVideoList((prevList) => prevList.filter((video) => video !== videoName));
    } catch (error) {
      console.error('Error archiving video:', error);
      setError(error.message);
    }
  };

  const unArchiveVideo = async (videoName) => {
    console.log('unarchiving')
    try {
      const fileName = `${userId}+${videoName}`;
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/unarchive?fileName=${encodeURIComponent(fileName)}`, 
        {
          method: 'POST',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to unarchive video');
      }
    } catch (error) {
      console.error('Error unarchiving video:', error);
      setError(error.message);
    }
  };

  const fetchArchivedVideos = async () => {
    setLoadingArchived(true);
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/listArchiveVideos?userId=${userId}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch archived videos');
      }
      const archivedVideos = await response.json();
      setArchivedVideoList(archivedVideos);
      setLoadingArchived(false);
    } catch (error) {
      console.error('Error fetching archived videos:', error);
      setError(error.message);
      setLoadingArchived(false);
    }
  };

  const handleArchiveVideo = (video) => {
    archiveVideo(video);
  };

  const handleUnarchiveVideo =(video) => {
    console.log('unarchive')
    unArchiveVideo(video);
  }

  const handleVideoSelect = (video) => {
    fetchVideoUrl(video);
    onSelectVideo(video);
  };

  const toggleArchivedVideos = () => {
    if (!showArchivedVideos) {
      fetchArchivedVideos();
    }
    setShowArchivedVideos(!showArchivedVideos);
  };

  if (loading) {
    return <p>Loading videos...</p>;
  }

  if (error) {
    return <p>Error loading videos: {error}</p>;
  }

  return (
    <div className="video-selector">
      <div className="video-list">
        {videoList.map((video, index) => (
          <div key={index} className="video-item">
            <button className="video-button" onClick={() => handleVideoSelect(video)}>
              <div className="thumbnail">
                <FaPlay className="play-icon" />
              </div>
              <span className="video-title">{video}</span>
            </button>
            <button className="archive-button" onClick={() => handleArchiveVideo(video)}>
              <FaArchive className="archive-icon" />
            </button>
          </div>
        ))}
      </div>

      <button className="toggle-archive-button" onClick={toggleArchivedVideos}>
        {showArchivedVideos ? 'Hide Archived Videos' : 'Show Archived Videos'}
      </button>

      {showArchivedVideos && (
        <div className="archived-video-list">
          {loadingArchived ? (
            <p>Loading archived videos...</p>
          ) : (
            archivedVideoList.map((video, index) => (
              <div key={index} className="video-item">
                <button className="video-button" onClick={() => handleVideoSelect(video)}>
                  <div className="thumbnail">
                    <FaPlay className="play-icon" />
                  </div>
                  <span className="video-title">{video}</span>
                </button>
                <button className="archive-button" onClick={() => handleUnarchiveVideo(video)}>
              <FaArchive className="archive-icon" />
            </button>
              </div>
            ))
          )}
        </div>
      )}

      {videoUrl && (
        <div className="video-player">
          <video controls src={videoUrl} />
        </div>
      )}
    </div>
  );
};

export default VideoSelector;
